<template>
  <div>
    <v-row class="mt-5">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn class="new-btn" @click="openNewChapterForm"
          v-if="addable">
          <i class="flaticon2-add-1 mr-2"></i>
          Nuovo capitolo
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="mt-5 mb-5"></v-divider>

    <v-treeview :items="this.chapterTree">
      <template v-slot:append="{ item }">
        <template>
          <v-btn-toggle dense style="background-color: transparent">
            <!-- <v-btn class="new-btn" @click="openNewChapterForm(item)"
              ><i class="flaticon2-add-1"></i
            ></v-btn> -->
            <v-btn class="edit-btn" @click="edit(item)"
              ><i class="flaticon2-edit"></i
            ></v-btn>
            <v-btn class="delete-btn" @click="confirmDelete(item)"
              v-if="deletable">
              <i class="flaticon2-rubbish-bin"></i>
            </v-btn>
          </v-btn-toggle>
        </template>
      </template>
    </v-treeview>

    <ChapterForm
      :chapters="firstLevelChapters"
      :pageId="pageId"
      :parentName="parentName"
      :showEditForm="showEditForm"
      :showNewForm="showNewForm"
      :chapter="selectedChapter"
      @change="changed"
      @close="closeDialog"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  DELETE_CHAPTER,
  SET_CREATABLE,
  SET_DELETABLE
} from "@/core/services/store/chapters.module";
import ChapterForm from "@/view/components/Block/Forms/ChapterForm.vue";
export default {
  components: {
    ChapterForm,
    // draggable,
  },

  data: () => ({
    chapterTree: [],
    selectedChapter: null,
    firstLevelChapters: [],
    parentName: null,
    showEditForm: false,
    showNewForm: false,
  }),

  props: {
    chapters: {
      type: Array,
      default: function () {
        return [];
      },
    },

    pageId: {
      type: String,
    },
    
    addable: {
      type: Boolean,
      default: true,
    },
    
    creatable: {
      type: Boolean,
      default: true,
    },
    
    deletable: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    this.refreshTreeView();
  },

  watch: {
    chapters() {
      this.refreshTreeView();
    },
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    refreshTreeView() {
      this.chapterTree = [];
      var chapterNumber = 0;

      this.chapters.forEach((chapter) => {
        var subchapterNumber = 1;
        var children = [];

        chapter.chapters.forEach((subChapter) => {
          children.push({
            id: subChapter.id,
            name:
              chapterNumber + "." + subchapterNumber + ". " + subChapter.title,
          });

          subchapterNumber += 1;
        });

        var parent = {
          id: chapter.id,
          name:
            chapterNumber == 0 ? "Intro" : chapterNumber + ". " + chapter.title,
          children: children,
        };

        this.chapterTree.push(parent);
        this.firstLevelChapters.push({ id: chapter.id, name: chapter.title });

        chapterNumber += 1;
      });
    },
    closeDialog() {
      this.showNewForm = false;
      this.showEditForm = false;
    },

    changed() {
      this.$emit("change");
    },

    confirmDelete(item) {
      confirm(
        "Sicuro di voler eliminare questo elemento?\nL'operazione è irreversibile."
      ) && this.delete(item);
    },

    delete(item) {
      this.$store.dispatch(DELETE_CHAPTER, item.id).then(() => {
        this.showSnack({
          text: "Capitolo eliminato con successo.",
          color: "success",
          timeout: 3500,
        });

        this.changed();
      });
    },

    edit(item) {
      this.$store.commit(SET_CREATABLE, this.creatable);
      this.$store.commit(SET_DELETABLE, this.deletable);
      
      this.$router.push({
        name: "modifica-capitolo",
        params: {
          id: item.id,
          name: item.name,
        },
      });
    },

    openNewChapterForm(item) {
      if (item != null) {
        this.selectedChapter = item;
        this.parentName = item.name;
        this.showEditForm = true;
      } else {
        this.selectedChapter = null;
        this.showNewForm = true;
      }
    },
  },
};
</script>