<template>
  <v-row>
    <v-col class="d-flex justify-end">
      <v-dialog v-model="showForm" width="450" persistent>
        <v-card>
          <v-card-title class="text-h5 primary" v-if="showNewForm">
            Nuovo capitolo
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Titolo"
                  v-model="form.title"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="form.parentName"
                  :items="chaptersSelect"
                  label="Capitolo padre"
                  clearable
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              dark
              class="mr-2 submit"
              @click="closeDialog"
              >Annulla</v-btn
            >
            <v-btn color="primary" @click="submit"> Salva </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>


<script>
import { mapActions } from "vuex";
import { CREATE_CHAPTER } from "@/core/services/store/chapters.module";

export default {
  name: "ChapterForm",

  props: {
    chapters: {
      type: Array,
      default: function () {
        return [];
      },
    },

    pageId: {
      type: String,
    },

    parentName: {
      type: String,
    },

    showNewForm: {
      type: Boolean,
      default: false,
    },

    showEditForm: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        title: null,
        parentName: null,
        pageId: null,
      },

      chaptersSelect: [],
      isFormValid: false,
    };
  },

  watch: {
    showEditForm(newValue) {
      if (newValue) {
        this.initForm();
      }
    },

    showNewForm(newValue) {
      if (newValue) {
        this.initForm();
      }
    },
  },

  computed: {
    showForm: {
      get() {
        return this.showNewForm || this.showEditForm;
      },
      set() {},
    },
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    closeDialog() {
      this.$emit("close");
    },

    initForm() {
      this.chaptersSelect = [];
      this.chapters.forEach((chapter) => {
        this.chaptersSelect.push(chapter.name);
      });

      this.form.title = null;
      this.form.parentName = null;
    },

    submit() {
      this.validateForm();

      if (this.isFormValid) {
        let params = {};

        if (this.form.parentName != null) {
          let selectedChapter = this.chapters.filter(
            (elem) => elem.name == this.form.parentName
          );

          params = {
            title: this.form.title,
            parent_id: selectedChapter[0].id,
          };
        } else {
          params = {
            title: this.form.title,
            page_id: this.pageId,
          };
        }

        this.$store.dispatch(CREATE_CHAPTER, params).then(() => {
          this.closeDialog();

          this.showSnack({
            text: "Capitolo aggiornato con successo.",
            color: "success",
            timeout: 3500,
          });

          this.$emit("change");
        });
      }
    },

    validateForm() {
      this.isFormValid = this.form.title != null;
    },
  },
};
</script>
